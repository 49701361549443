// React & friends
import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

// Utils
import * as FullStory from '@fullstory/browser';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  serviceType,
  isValidRoute,
  isNotPrevSuccess,
  shipmentRequirements,
  handleBorderCrossing,
  variableFuel,
} from 'core/utils/routeUtils';

// API Request hooks && success/redirect Paths
import { fetchQuoteRequest, createQuoteRequest } from 'core/api/quoteRequest';
import * as paths from 'routes/paths';

// Data and validations
import validations from '../validations';
import { optionToValue } from 'core/utils';

// Constants
const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

export const useQuoteRequest = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const [validationErrors, setValidationErrors] = useState({});
  const {
    data,
    isLoading: isQuoteRequestLoading,
    error,
  } = useQuery(['quote', uuid], () => fetchQuoteRequest(uuid), {
    staleTime: FIVE_MINUTES_IN_MS,
    onSuccess: data => {
      validations.forEach(fn => {
        const validateEntity = fn(data, t);
        if (validateEntity.isInvalid) {
          setValidationErrors({
            ...validationErrors,
            [validateEntity['type']]: validateEntity.validationMessage,
          });
        }
      });
    },
  });

  return {
    companyId: get(data, 'company.id', ''),
    companyName: get(data, 'hubspot.company.properties.name', ''),
    dealName: get(data, 'hubspot.deal.properties.dealname', ''),
    error,
    isQuoteRequestLoading,
    validationErrors,
    company: {
      createdAt: get(data, 'company.created_at', ''),
    },
    verificationStepValues: {
      company: get(data, 'hubspot.company.properties.name', ''),
      companyId: get(data, 'company.id', ''),
      industry: get(data, 'hubspot.company.properties.industry', ''),
      country: get(data, 'hubspot.company.properties.country', ''),
      contacts: get(data, 'hubspot.contacts', []).map(contact => {
        return {
          first_name: get(contact, 'properties.firstname'),
          last_name: get(contact, 'properties.lastname'),
          email: get(contact, 'properties.email'),
          phone_number: get(contact, 'properties.phone'),
        };
      }),
    },
  };
};

export const useCreateQuoteRequestMutation = () => {
  const { uuid } = useParams();
  const history = useHistory();
  const mutation = useMutation(
    ({ values, prevResults }) => {
      if (!values.companyId) {
        throw new Error(
          `A company id is required to complete this process! Please contact #tech-support in Slack.`
        );
      }

      const payload = {
        request_type: values.request_type?.value,
        commodities: [values.commodity.id],
        company_id: values.companyId,
        truck_type: 'dryvan',
        currency: optionToValue(values.currency),
        requested_platform: 'white_glove',
        service_type: serviceType(values),
        notes: [values.notes],
        length: values.length,
        width: values.width,
        height: values.height,
        temperature: values.temperature,
        shipment_requirements: shipmentRequirements(values),
        additional_services: values.additional_services,
        handle_border_crossing: handleBorderCrossing(values),
        variable_fuel: variableFuel(values),
      };

      payload.routes = values.routes
        .filter(r => isValidRoute(r) && isNotPrevSuccess(r, prevResults))
        .map(({ origin, destination, loads_per_month, id: callback_id }) => ({
          origin_geolocation_id: origin.id,
          destination_geolocation_id: destination.id,
          callback_id,
          loads_per_month,
        }));

      if (payload.routes.length < 1) {
        return;
      }

      return createQuoteRequest({ uuid, payload });
    },
    {
      onSuccess: () => {
        FullStory.event('Submitted White Glove Quote Request');
        history.push(paths.app.success);
      },
    }
  );

  return mutation;
};
