import { CTAButton, Text, Label } from '@nuvocargo/nuvo-styleguide';
import {
  TextField,
  Textarea,
  Dropdown,
  Checkbox,
} from '@nuvocargo/nuvo-styleguide/forms/formik';
import { Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useUser } from 'context';
import { getUsedLanguage } from 'core/utils';
import { ROUTE_STATE } from 'core/utils/routeUtils';
import { placeIdToNumber } from '../utils';
import SavePlace from '../SavePlace';
import Calendar from '@nuvocargo/nuvo-styleguide/Calendar';
import { useCreateShipment } from './hooks';
import {
  APPOINTMENT_TYPES,
  createAdditionalServicesOptions,
  createAppointmentTypeOptions,
  createClientResponsibleForBorderCrossingOptions,
  createTrialPeriodOptions,
  createInitialValues,
  createRequestTypeOptions,
  createRequiresPitaOptions,
  createTypeOfLoadUnloadOptions,
  createValidationSchema,
  routeToCreateLocation,
  transformFormToRequestBody,
  ADDITIONAL_SERVICES,
  createIsFifoOptions,
  getAppointmentInfoAtLocation,
  formatISOWithTimezone,
} from './data';
import { FormWrapper, Row } from './styles';
import ErrorsAndLoader from './ErrorsAndLoader';
import FacilityGroup from './FacilityGroup';
import CustomPlaceDropdown from './CustomPlaceDropdown';

const ShipmentForm = () => {
  const { t, i18n } = useTranslation();
  const {
    companies,
    createShipment,
    destinations,
    isCompaniesError,
    isLoading,
    isRouteError,
    isShipmentError,
    isSubmitting,
    legsCrossMexicanBorder,
    loadInstructions,
    locationModalState,
    onPlaceSave,
    origins,
    route,
    setLocationModalState,
    shipmentCreateError,
  } = useCreateShipment(t);
  const { currentUser } = useUser();
  const requestTypeOptions = createRequestTypeOptions(t);
  const additionalServicesOptions = createAdditionalServicesOptions(t);
  const appointmentTypeOptions = createAppointmentTypeOptions(t);
  const trialPeriodOptions = createTrialPeriodOptions(t);
  const typeOfLoadUnloadOptions = createTypeOfLoadUnloadOptions(t);
  const clientResponsibleForBorderCrossingOptions =
    createClientResponsibleForBorderCrossingOptions(t);
  const requiresPitaOptions = createRequiresPitaOptions(t);
  const isFifoOptions = createIsFifoOptions(t);

  // Use Route's Appointment Info as default values for the new Shipment
  const appointmentAtOrigin = getAppointmentInfoAtLocation(route?.origin);
  const appointmentAtDestination = getAppointmentInfoAtLocation(
    route?.destination
  );

  return (
    <ErrorsAndLoader
      isLoading={isLoading}
      isRouteError={isRouteError}
      isInactiveRoute={route?.state !== ROUTE_STATE.ACTIVE}
      routeHasNoCompany={!route?.company}
      placesAreBroken={origins?.length === 1 || destinations?.length === 1}
      isOnFinancialHold={!!route?.company?.financeHold}>
      <SavePlace
        onSaveCallback={onPlaceSave}
        saveModal={locationModalState}
        closeModal={() => {
          setLocationModalState();
        }}
        location={
          route && locationModalState
            ? routeToCreateLocation(
                route[locationModalState.property],
                route.company.id
              )
            : null
        }
        companyId={route?.companyId}
      />

      <Formik
        initialValues={createInitialValues({
          origin: origins?.length
            ? origins.find(({ id }) => id === route.origin.id)
            : null,
          destination: destinations?.length
            ? destinations.find(({ id }) => id === route.destination.id)
            : null,
          readyForPickup: appointmentAtOrigin.lowerBoundWindow,
          upperBoundForPickupWindowAt: appointmentAtOrigin.upperBoundWindow,
          lowerBoundForDropoffWindowAt:
            appointmentAtDestination.lowerBoundWindow,
          upperBoundForDropoffWindowAt:
            appointmentAtDestination.upperBoundWindow,
          appointmentTypeAtOrigin:
            appointmentTypeOptions.find(
              option => option.value === appointmentAtOrigin.appointmentType
            ) || appointmentTypeOptions[0],
          isAppointmentTypeAtOriginFifo:
            isFifoOptions.find(
              option => option.value === appointmentAtOrigin.fifo
            ) || isFifoOptions[0],
          appointmentTypeAtDestination:
            appointmentTypeOptions.find(
              option =>
                option.value === appointmentAtDestination.appointmentType
            ) || appointmentTypeOptions[0],
          isAppointmentTypeAtDestinationFifo:
            isFifoOptions.find(
              option => option.value === appointmentAtDestination.fifo
            ) || isFifoOptions[0],
          typeOfLoadUnloadAtOrigin:
            typeOfLoadUnloadOptions.find(
              option => option.value === appointmentAtOrigin.loadingScheme
            ) || typeOfLoadUnloadOptions[0],
          typeOfLoadUnloadAtDestination:
            typeOfLoadUnloadOptions.find(
              option => option.value === appointmentAtDestination.loadingScheme
            ) || typeOfLoadUnloadOptions[0],
          loadInstructions,
          representativeId: currentUser?.id,
          requestType: requestTypeOptions[0],
          requiresPita: requiresPitaOptions[0],
          legsCrossMexicanBorder,
          withCustoms: route?.additionalServices?.includes(
            ADDITIONAL_SERVICES.CUSTOMS
          ),
          withCrossBorderInsurance: route?.additionalServices?.includes(
            ADDITIONAL_SERVICES.CROSS_BORDER_INSURANCE
          ),
          withFinancing: route?.additionalServices?.includes(
            ADDITIONAL_SERVICES.FINANCE
          ),
          trialPeriod: {
            label: route?.company?.trialPeriod
              ? t('trial-period-yes')
              : t('trial-period-no'),
            value: route?.company?.trialPeriod,
          },
        })}
        validationSchema={createValidationSchema(t)}
        onSubmit={data => {
          const readyForPickup = formatISOWithTimezone(data.readyForPickup);
          const upperBoundForPickupWindowAt = formatISOWithTimezone(
            data.upperBoundForPickupWindowAt
          );
          const lowerBoundForDropoffWindowAt = formatISOWithTimezone(
            data.lowerBoundForDropoffWindowAt
          );
          const upperBoundForDropoffWindowAt = formatISOWithTimezone(
            data.upperBoundForDropoffWindowAt
          );

          createShipment(
            transformFormToRequestBody({
              ...data,
              readyForPickup,
              upperBoundForPickupWindowAt,
              lowerBoundForDropoffWindowAt,
              upperBoundForDropoffWindowAt,
              destinationId: placeIdToNumber(data.destination.id),
              legsCrossMexicanBorder,
              originId: placeIdToNumber(data.origin.id),
              route,
            })
          );
        }}>
        {({ values, setFieldValue, isValid, errors }) => (
          <FormWrapper data-testid="lofi-form">
            {legsCrossMexicanBorder && (
              <>
                <Row>
                  <Dropdown
                    id="client-responsible-for-border-crossing"
                    label={t('shipment-form-client-border-crossing-label')}
                    name="clientResponsibleForBorderCrossing"
                    placeholder={t('shipment-form-route-placeholder')}
                    disabled={isSubmitting}
                    options={clientResponsibleForBorderCrossingOptions}
                    value={values.clientResponsibleForBorderCrossing}
                    error={errors.clientResponsibleForBorderCrossing}
                    isSearchable
                  />
                </Row>

                <Row>
                  <Dropdown
                    id="requires-pita"
                    label={t('shipment-form-requires-pita-label')}
                    name="requiresPita"
                    placeholder={t('shipment-form-requires-pita-placeholder')}
                    disabled={isSubmitting}
                    options={requiresPitaOptions}
                    value={values.requiresPita}
                    isSearchable
                  />
                </Row>
              </>
            )}

            <Row>
              <Dropdown
                id="request-type"
                label={t('shipment-form-request-type-label')}
                name="requestType"
                placeholder={t('shipment-form-request-type-placeholder')}
                disabled={isSubmitting}
                options={requestTypeOptions}
                value={values.requestType}
                isSearchable
              />
            </Row>

            <Row>
              <TextField
                id="customer-reference"
                label={t('shipment-form-customer-reference-label')}
                name="customerReference"
                disabled={isSubmitting}
              />
            </Row>

            <Row>
              {isCompaniesError && (
                <Text color="red">{t('shipment-form-company-api-error')}</Text>
              )}

              {!isCompaniesError && companies.length > 0 && (
                <Dropdown
                  id="invoice-to-child-company"
                  label={t('shipment-form-invoice-to-child-company-label')}
                  name="invoiceToCompanyId"
                  placeholder={t(
                    'shipment-form-invoice-to-child-company-placeholder'
                  )}
                  disabled={isSubmitting}
                  options={companies}
                  value={values.invoiceToCompanyId}
                  isSearchable
                />
              )}
            </Row>

            <Row>
              <CustomPlaceDropdown
                id="origin"
                label={t('shipment-form-origin-label')}
                name="origin"
                placeholder={t('shipment-form-origin-placeholder')}
                disabled={isSubmitting}
                options={origins}
                setFieldValue={setFieldValue}
                type="origin"
                onChange={option => {
                  if (option.value === 'add') {
                    return setLocationModalState({
                      property: 'origin',
                      type: 'create',
                    });
                  }
                }}
                isSearchable
              />
            </Row>
            <FacilityGroup title={t('shipment-form-appointment-at-origin')}>
              <Row>
                {/* Loading Schema */}
                <Dropdown
                  id="type-of-load-unload-at-origin"
                  label={t('shipment-form-type-of-load-unload-at-origin-label')}
                  name="typeOfLoadUnloadAtOrigin"
                  placeholder={t(
                    'shipment-form-type-of-load-unload-at-origin-placeholder'
                  )}
                  disabled={isSubmitting}
                  options={typeOfLoadUnloadOptions}
                  value={values.typeOfLoadUnloadAtOrigin}
                  isSearchable
                />
              </Row>

              <Row>
                {/* Appointment Type */}
                <Dropdown
                  id="appointment-type-at-origin"
                  label={t('shipment-form-appointment-type-at-origin-label')}
                  name="appointmentTypeAtOrigin"
                  placeholder={t(
                    'shipment-form-appointment-type-at-origin-placeholder'
                  )}
                  disabled={isSubmitting}
                  options={appointmentTypeOptions}
                  value={values.appointmentTypeAtOrigin}
                  isSearchable
                />
              </Row>

              {values.appointmentTypeAtOrigin?.value ===
                APPOINTMENT_TYPES.WINDOW && (
                <Row data-testid="is-appointment-type-at-origin-fifo">
                  {/* Is FIFO? */}
                  <Dropdown
                    id="is-appointment-type-at-origin-fifo"
                    label={t('shipment-form-is-fifo')}
                    name="isAppointmentTypeAtOriginFifo"
                    placeholder={t('shipment-form-is-fifo-placeholder')}
                    disabled={isSubmitting}
                    options={isFifoOptions}
                    value={values.isAppointmentTypeAtOriginFifo}
                    error={errors.isAppointmentTypeAtOriginFifo}
                    isSearchable
                  />
                </Row>
              )}

              <Row data-testid="ready-for-pickup-date-wrapper">
                {/* Lower Bound Window datetime */}
                <Calendar
                  id="ready-for-pickup-date"
                  key={values.origin?.id}
                  label={
                    values.appointmentTypeAtOrigin?.value ===
                    APPOINTMENT_TYPES.WINDOW
                      ? t(
                          'shipment-form-lower-bound-for-pickup-window-at-label'
                        )
                      : t('shipment-form-ready-for-pickup-date-label')
                  }
                  placeholder={t('choose_date')}
                  dateFormat="EEEE d MMM - HH:mm"
                  disabled={isSubmitting}
                  isReadOnly={false}
                  minDate={new Date()}
                  name="readyForPickup"
                  onChange={setFieldValue}
                  showTimeSelect
                  timeCaption="Time"
                  timeFormat="HH:mm"
                  value={values.readyForPickup}
                  locale={getUsedLanguage(i18n?.language)}
                  error={errors.readyForPickup}
                />
              </Row>

              {values.appointmentTypeAtOrigin?.value ===
                APPOINTMENT_TYPES.WINDOW && (
                <Row data-testid="upper-bound-for-pickup-window-at-wrapper">
                  {/* Upper Bound Window datetime */}
                  <Calendar
                    key={values.origin?.id}
                    id="upper-bound-for-pickup-window-at"
                    label={t(
                      'shipment-form-upper-bound-for-pickup-window-at-label'
                    )}
                    placeholder={t('choose_date')}
                    dateFormat="EEEE d MMM - HH:mm"
                    disabled={isSubmitting}
                    isReadOnly={false}
                    minDate={new Date()}
                    name="upperBoundForPickupWindowAt"
                    onChange={setFieldValue}
                    showTimeSelect
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    value={values.upperBoundForPickupWindowAt}
                    locale={getUsedLanguage(i18n?.language)}
                    error={errors.upperBoundForPickupWindowAt}
                  />
                </Row>
              )}

              <Row style={{ marginBottom: 0 }}>
                {/* reuse origin appointment */}
                <Checkbox
                  size="medium"
                  id="reuse-appointment-info-at-origin"
                  name="reuseAppointmentInfoAtOrigin"
                  label={t('shipment-form-reuse-appointment-info-at-origin')}
                />
              </Row>
            </FacilityGroup>

            <Row>
              <CustomPlaceDropdown
                id="destination"
                label={t('shipment-form-destination-label')}
                name="destination"
                placeholder={t('shipment-form-destination-placeholder')}
                disabled={isSubmitting}
                options={destinations}
                setFieldValue={setFieldValue}
                type="destination"
                onChange={option => {
                  if (option.value === 'add') {
                    return setLocationModalState({
                      property: 'destination',
                      type: 'create',
                    });
                  }
                }}
                isSearchable
              />
            </Row>
            <FacilityGroup
              title={t('shipment-form-appointment-at-destination')}>
              <Row>
                {/* Loading Schema */}
                <Dropdown
                  id="type-of-load-unload-at-destination"
                  label={t(
                    'shipment-form-type-of-load-unload-at-destination-label'
                  )}
                  name="typeOfLoadUnloadAtDestination"
                  placeholder={t(
                    'shipment-form-type-of-load-unload-at-destination-placeholder'
                  )}
                  disabled={isSubmitting}
                  options={typeOfLoadUnloadOptions}
                  value={values.typeOfLoadUnloadAtDestination}
                  isSearchable
                />
              </Row>

              <Row>
                <Dropdown
                  id="appointment-type-at-destination"
                  label={t(
                    'shipment-form-appointment-type-at-destination-label'
                  )}
                  name="appointmentTypeAtDestination"
                  placeholder={t(
                    'shipment-form-appointment-type-at-destination-placeholder'
                  )}
                  disabled={isSubmitting}
                  options={appointmentTypeOptions}
                  value={values.appointmentTypeAtDestination}
                  isSearchable
                />
              </Row>

              {values.appointmentTypeAtDestination?.value ===
                APPOINTMENT_TYPES.WINDOW && (
                <Row data-testid="is-appointment-type-at-destination-fifo">
                  {/* Is FIFO? */}
                  <Dropdown
                    id="is-appointment-type-at-destination-fifo"
                    label={t('shipment-form-is-fifo')}
                    name="isAppointmentTypeAtDestinationFifo"
                    placeholder={t('shipment-form-is-fifo-placeholder')}
                    disabled={isSubmitting}
                    options={isFifoOptions}
                    value={values.isAppointmentTypeAtDestinationFifo}
                    error={errors.isAppointmentTypeAtDestinationFifo}
                    isSearchable
                  />
                </Row>
              )}

              {values.appointmentTypeAtDestination?.value !==
                APPOINTMENT_TYPES.EXACT_TIME && (
                <Row data-testid="lower-bound-for-dropoff-window-at-wrapper">
                  {/* Lower Bound Window datetime */}
                  <Calendar
                    id="lower-bound-for-dropoff-window-at"
                    key={values.destination?.id}
                    label={t('shipment-form-ready-for-dropoff-date-label')}
                    placeholder={t('choose_date')}
                    dateFormat="EEEE d MMM - HH:mm"
                    disabled={isSubmitting}
                    isReadOnly={false}
                    minDate={new Date()}
                    name="lowerBoundForDropoffWindowAt"
                    onChange={setFieldValue}
                    showTimeSelect
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    value={values.lowerBoundForDropoffWindowAt}
                    locale={getUsedLanguage(i18n?.language)}
                    error={errors.lowerBoundForDropoffWindowAt}
                  />
                </Row>
              )}

              {values.appointmentTypeAtDestination?.value ===
                APPOINTMENT_TYPES.WINDOW && (
                <Row data-testid="upper-bound-for-dropoff-window-at-wrapper">
                  {/* Upper Bound Window datetime */}
                  <Calendar
                    id="upper-bound-for-dropoff-window-at"
                    key={values.destination?.id}
                    label={t(
                      'shipment-form-upper-bound-for-dropoff-window-at-label'
                    )}
                    placeholder={t('choose_date')}
                    dateFormat="EEEE d MMM - HH:mm"
                    disabled={isSubmitting}
                    isReadOnly={false}
                    minDate={new Date()}
                    name="upperBoundForDropoffWindowAt"
                    onChange={setFieldValue}
                    showTimeSelect
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    value={values.upperBoundForDropoffWindowAt}
                    locale={getUsedLanguage(i18n?.language)}
                    error={errors.upperBoundForDropoffWindowAt}
                  />
                </Row>
              )}

              <Row style={{ marginBottom: 0 }}>
                {/* reuse destination appointment */}
                <Checkbox
                  size="medium"
                  id="reuse-appointment-info-at-destination"
                  name="reuseAppointmentInfoAtDestination"
                  label={t(
                    'shipment-form-reuse-appointment-info-at-destination'
                  )}
                />
              </Row>
            </FacilityGroup>

            <Row>
              <Textarea
                id="load-instructions"
                label={t('shipment-form-load-instructions-label')}
                name="loadInstructions"
                placeholder={t('shipment-form-load-instructions-placeholder')}
                disabled={isSubmitting}
              />
            </Row>

            <Row>
              <TextField
                id="number-of-copies"
                label={t('shipment-form-number-of-copies-label')}
                name="numberOfCopies"
                placeholder={t('shipment-form-number-of-copies-placeholder')}
                type="number"
                disabled={isSubmitting}
              />
            </Row>

            <Row>
              {/* <AdditionalServices /> */}
              <Stack space="24px">
                <Label color="steel">{t('additional-services')}</Label>
                <Stack space="12px">
                  {additionalServicesOptions.map(as => (
                    <Checkbox
                      styles={{
                        root: { width: 23, height: 23 },
                        label: { fontSize: '16px', marginLeft: '12px' },
                      }}
                      key={as.value}
                      id={as.value}
                      name={as.value}
                      label={as.label}
                    />
                  ))}
                </Stack>
              </Stack>
            </Row>

            <Row>
              <Stack space="24px">
                <Stack space="12px">
                  <Dropdown
                    id="trial-period"
                    label={t('shipment-form-trial-period-label')}
                    name="trialPeriod"
                    placeholder={t('shipment-form-trial-period-placeholder')}
                    disabled={isSubmitting}
                    options={trialPeriodOptions}
                    value={values.trialPeriod}
                  />
                </Stack>
              </Stack>
            </Row>

            {isShipmentError && (
              <Row>
                <Text color="red">
                  {t('shipment-form-shipment-creation-error', {
                    error: shipmentCreateError,
                  })}
                </Text>
              </Row>
            )}

            <Stack styles={{ root: { alignItems: 'flex-end' } }}>
              <CTAButton type="submit" disabled={isSubmitting || !isValid}>
                {t('shipment-form-create-shipment-submit')}
              </CTAButton>
            </Stack>
          </FormWrapper>
        )}
      </Formik>
    </ErrorsAndLoader>
  );
};

export default ShipmentForm;
