import { lazy, Suspense } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { GlobalStyle, theme } from 'styles';
import * as sentry from 'core/integrations/sentry';
import { Spinner } from 'components/generic/kit';
import { useUser } from 'context';

const AuthenticatedApp = lazy(() =>
  import(/* webpackPrefetch: true */ './routes/authenticated-routes')
);
const UnauthenticatedApp = lazy(() =>
  import('./routes/unauthenticated-routes')
);

function App() {
  const { isAuthenticated, isLoading, backUrl } = useUser();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <QueryParamProvider
          adapter={ReactRouter5Adapter}
          options={{ updateType: 'replaceIn' }}>
          <Suspense fallback={<Spinner />}>
            <sentry.ErrorBoundary>
              {isLoading ? (
                <Spinner />
              ) : isAuthenticated ? (
                <AuthenticatedApp />
              ) : (
                <UnauthenticatedApp backUrl={backUrl} />
              )}
            </sentry.ErrorBoundary>
          </Suspense>
        </QueryParamProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
