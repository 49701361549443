import { client } from 'core/utils/axios.config';

export const fetchCommodities = (companyId = '') =>
  client
    .get('edge/commodities.json', {
      params: {
        company_id: companyId || '',
      },
    })
    .then(response => response.data);

export const createCommodity = data =>
  client.post(`edge/commodities.json`, data).then(response => response.data);
