import { useMutation, useQueryClient } from 'react-query';
import * as FullStory from '@fullstory/browser';
import { useParams } from 'react-router-dom';

import { updatePlace } from 'core/api/places';

/**
 * Executes the mutation with a minimum delay of 800ms
 *
 * @param {*} data
 * @returns
 */
const updatePlaceWithDelay = async data => {
  const [result] = await Promise.allSettled([
    updatePlace({
      body: {
        name: data.name,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        contactName: data.contactName,
        contactTelephone: data.contactTelephone,
        id: data.id,
        facilityProcedure: {
          appointmentType: data.appointmentType.value,
          loadingScheme: data.loadingScheme.value,
          startTime: data.startTime,
          endTime: data.endTime,
          receivingDays: data.receivingDays.value,
          loadingDuration: data.loadingDuration.value,
          facilityInstructions: data.facilityInstructions,
        },
      },
    }),
    new Promise(resolve => setTimeout(resolve, 800)),
  ]);

  if (result.status === 'rejected') {
    throw result.reason;
  }

  return result?.value;
};

export const useUpdatePlace = queryConfig => {
  const queryClient = useQueryClient();
  const { id: routeId } = useParams();

  return useMutation(updatePlaceWithDelay, {
    onSuccess: updatedPlace => {
      FullStory.event('Updated place information for route', {
        updatedPlace,
      });

      queryClient.invalidateQueries(['getRouteDetailsRequest', routeId]);
      // @todo: remove this one when _configured_ props from _route_ is working
      queryClient.invalidateQueries('places');
      queryConfig?.onSuccess(updatedPlace);
    },
    onError: error => {
      FullStory.event('Error trying to update place information for route', {
        error,
      });
      queryConfig?.onError();
    },
  });
};
