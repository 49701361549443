// React & Friends
import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import { createPortal } from 'react-dom';

// Utilities
import { useTranslation } from 'react-i18next';
import { commodityCategoryOptions } from 'core/utils/commodityUtils';
import { getCommodityModalValidation } from 'screens/quote-request/validations';

// UX & UI
import { Heading, CTAButton, Label, Text } from '@nuvocargo/nuvo-styleguide';

import { TextField, Dropdown } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { motion } from 'framer-motion';

// Local Components
import { ErrorMessage, Switch } from 'components/generic/form';
import { Modal, Box, Flex, Stack } from 'components/generic/kit';

// Hooks
import { useCreateCommodityMutation } from 'core/hooks';
import InputTable from '@nuvocargo/nuvo-styleguide/InputTable/formik';
import useAddCommodityModal from './useAddCommodityModal';

// Constants

const Portal = ({ children }) => {
  return createPortal(children, document.getElementById('modals-root'));
};

export default function AddCommodityModal({
  currency = 'USD',
  onSubmit,
  onSuccess,
  onClose,
  isError: propError,
  isLoading: propLoading,
  companyId,
  accessorials,
  ...props
}) {
  const { t } = useTranslation();
  const { mutate, isLoading, isError } = useCreateCommodityMutation({
    companyId: companyId,
  });
  const { specialRequirementsColumns, specialRequirementsInitialValue } =
    useAddCommodityModal({ t, accessorials });
  const category_options = useMemo(() => commodityCategoryOptions(t), []);

  const isDisabled = !companyId;

  return (
    <Portal>
      <Modal {...props} onClose={onClose} containerStyle={{ width: '884px' }}>
        <Formik
          initialValues={{
            category: category_options[0],
            name: '',
            toggleShipmentValue: false,
            valuePerLoad: 100000,
            weight: 20000,
            specialRequirements: specialRequirementsInitialValue,
            currency,
          }}
          validationSchema={getCommodityModalValidation({ t })}
          onSubmit={(values, actions) => {
            if (typeof onSubmit === 'function') {
              return onSubmit(values, actions);
            }

            mutate({ ...values, companyId }, { onSuccess });
          }}>
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <motion.div layout>
                  <Heading variant="h5b">Add a new commodity</Heading>
                </motion.div>

                <Dropdown
                  id="category"
                  autocomplete
                  error={errors.category}
                  label="Category"
                  name="category"
                  options={category_options}
                  value={values.category}
                  onChange={data => {
                    setFieldValue('category', data);
                  }}
                />

                <TextField
                  name="name"
                  label="Item description"
                  styles={{
                    root: { marginTop: '32px' },
                  }}
                />

                <Stack space="8px" styles={{ root: { marginTop: '24px' } }}>
                  <Label color={'steel'}>Weight</Label>
                  <Box
                    styles={{
                      root: {
                        position: 'relative',
                      },
                    }}>
                    <TextField
                      name="weight"
                      type="number"
                      styles={{
                        root: { width: '100%' },
                      }}
                    />
                    <Flex
                      styles={{
                        root: {
                          position: 'absolute',
                          width: '80px',
                          height: '56px',
                          top: '0%',
                          right: '0%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      }}>
                      <Text
                        color="forest"
                        styles={{
                          regular: {
                            fontFamily: 'value-sans-medium',
                          },
                        }}>
                        KG
                      </Text>
                    </Flex>
                  </Box>
                </Stack>

                <motion.div layout>
                  <Flex
                    styles={{
                      root: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '40px',
                      },
                    }}>
                    <Heading variant="h6" color="green">
                      Average shipment value is over $100,000 USD
                    </Heading>
                    <Switch name="toggleShipmentValue" />
                  </Flex>
                </motion.div>

                {values.toggleShipmentValue ? (
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    <Stack space="8px" styles={{ root: { marginTop: '24px' } }}>
                      <Label color={'steel'}>Enter value per load</Label>
                      <Box
                        styles={{
                          root: {
                            position: 'relative',
                          },
                        }}>
                        <TextField
                          name="valuePerLoad"
                          type="number"
                          styles={{
                            root: { width: '100%' },
                          }}
                        />
                        <Flex
                          styles={{
                            root: {
                              position: 'absolute',
                              width: '80px',
                              height: '56px',
                              top: '0%',
                              right: '0%',
                              alignItems: 'center',
                              justifyContent: 'center',
                            },
                          }}>
                          <Text
                            color="forest"
                            styles={{
                              regular: {
                                fontFamily: 'value-sans-medium',
                              },
                            }}>
                            {currency}
                          </Text>
                        </Flex>
                      </Box>
                    </Stack>
                  </motion.div>
                ) : null}

                <motion.div layout>
                  <Stack styles={{ root: { marginTop: '40px' } }} space="24px">
                    <Heading variant="h6">
                      Special handling required (select all that apply)
                    </Heading>
                    <Stack space="12px">
                      <InputTable
                        hideActions
                        name="specialRequirements"
                        columns={specialRequirementsColumns}
                      />
                    </Stack>
                  </Stack>
                </motion.div>

                {isDisabled && (
                  <motion.div>
                    <ErrorMessage backgroundColor="taupe">
                      {/* FIXME: USE Translations, we need better errors from the API.*/}
                      You need to select a company to be able to add new
                      commodities.
                    </ErrorMessage>
                  </motion.div>
                )}

                {(isError || propError) && (
                  <motion.div>
                    <ErrorMessage backgroundColor="taupe">
                      {/* FIXME: USE Translations, we need better errors from the API.*/}
                      The commodity can not be created at the moment, please try
                      again later!
                    </ErrorMessage>
                  </motion.div>
                )}

                <motion.div layout>
                  <CTAButton
                    disabled={isLoading || propLoading || isDisabled}
                    type="submit"
                    styles={{
                      primary: {
                        marginTop: isError ? '16px' : '56px',
                        width: '230px',
                        height: '64px',
                      },
                    }}>
                    Add
                  </CTAButton>
                </motion.div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </Portal>
  );
}
