import { useTranslation } from 'react-i18next';
import {
  CTAButton,
  Eyebrow,
  Heading,
  Icon,
  Tag,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import Modal from '@nuvocargo/nuvo-styleguide/Modals/Modal';
import { useParams } from 'react-router-dom';

import { ReactComponent as SpinnerIcon } from 'assets/images/icons/spinner.svg';
import { formatAddressOneLine } from 'core/utils/routeUtils';
import { useUpdatePlace } from 'core/hooks/mutations/places';
import { useUpdateRoute } from 'core/api/routeRequest';
import {
  PROCEDURES_APPOINTMENT_TYPES_MAP,
  PROCEDURES_LOADING_SCHEMA_MAP,
  PROCEDURES_RECEIVING_DAYS_MAP,
} from 'core/utils/route-facility';
import { twentyFourStringToTwelve } from 'components/generic/form/formik/TimeSelector/TimeSelector';

import { ACTIONS, STEPS } from 'screens/route/Facilities';

const ConfirmationWizard = ({
  wizardState,
  dispatch,
  onCloseModal,
  addSuccessToast,
  addErrorToast,
}) => {
  const { t } = useTranslation();
  const params = useParams();

  const {
    modalData: placeData,
    from,
    facilityType,
    facilityProcedure,
  } = wizardState;
  const {
    zipCode: zipCodeObject,
    receivingDays: receivingDaysObject,
    loadingScheme: loadingSchemeObject,
    appointmentType: appointmentTypeObject,
    startTime,
    endTime,
  } = placeData;

  const updateProperty = facilityType;

  const hasFacilityProceduresInformation =
    facilityProcedure ||
    receivingDaysObject ||
    loadingSchemeObject ||
    appointmentTypeObject ||
    (startTime && endTime);

  const receivingDays = PROCEDURES_RECEIVING_DAYS_MAP[
    receivingDaysObject?.value
  ]
    ? t(`procedures-receiving-days-${receivingDaysObject.value}`)
    : t('procedures-not-available');

  const isValidLoadingScheme =
    !!PROCEDURES_LOADING_SCHEMA_MAP[loadingSchemeObject?.value];

  const isValidAppointmentType =
    !!PROCEDURES_APPOINTMENT_TYPES_MAP[appointmentTypeObject?.value];

  const onSuccessFlow = () => {
    onCloseModal();
    addSuccessToast();
  };
  const onErrorFlow = () => {
    onCloseModal();
    addErrorToast();
  };
  const updatePlaceMutation = useUpdatePlace({
    onSuccess: onSuccessFlow,
    onError: onErrorFlow,
  });

  const updateRouteMutation = useUpdateRoute({
    onSuccessCallback: onSuccessFlow,
    onErrorCallback: onErrorFlow,
    id: params.id,
    updateProperty,
  });

  const onConfirmClick = () => {
    if (from === STEPS.SELECT_EXISTING_PLACE_FORM) {
      updateRouteMutation.mutate({
        id: placeData.id,
      });
    }
    if (from === STEPS.PLACE_FORM) {
      updatePlaceMutation.mutate(placeData);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <Heading variant="h5" color="green">
          {t('facility-confirmation-modal-title')}
        </Heading>
        <div className="flex gap-2">
          <Icon name="error" size="small" color="darkGrey" />
          <Text color="steel" size="small">
            {t('facility-confirmation-modal-description')}
          </Text>
        </div>
      </div>
      <div className="p mt-9 bg-nuvo-gray-dark/[.03] p-8">
        <Heading variant="h6" color="green">
          {placeData.name}
        </Heading>
        <Text color="steel" size="regular">
          {formatAddressOneLine({
            addressLine1: placeData.addressLine1,
            zipCode: zipCodeObject,
          })}
        </Text>
        <div className="mt-6">
          <div className="flex flex-row">
            {/*procedues*/}
            <div
              className="flex flex-1 flex-col"
              data-testid="confirmation-modal-procedures-information">
              <Eyebrow
                size="extraSmall"
                color="steel"
                text={t('procedures-section-title')}
              />
              {hasFacilityProceduresInformation ? (
                <>
                  <div className="mt-2 flex flex-row gap-2">
                    {/*@todo: add this icon to the styleguide*/}
                    <Icon name="MdOutlineWatchLater" color="darkGrey" />
                    <div className="flex flex-1 flex-col">
                      <Text>
                        {twentyFourStringToTwelve(startTime)} -{' '}
                        {twentyFourStringToTwelve(endTime)}
                      </Text>
                      <Text size="small" color="steel">
                        {receivingDays}
                      </Text>
                    </div>
                  </div>
                  <div className="mt-2 flex gap-2">
                    {isValidLoadingScheme && (
                      <Tag>
                        {t(
                          `procedures-schema-${loadingSchemeObject.value}-option`
                        )}
                      </Tag>
                    )}
                    {isValidAppointmentType && (
                      <Tag>
                        {t(
                          `procedures-appointment-type-${appointmentTypeObject.value}-option`
                        )}
                      </Tag>
                    )}
                  </div>
                </>
              ) : (
                <Text>{t('procedures-not-available')}</Text>
              )}
            </div>

            {/*contact*/}
            <div className="flex flex-1 flex-col">
              <Eyebrow
                size="extraSmall"
                color="steel"
                text={t('facility-contact-section-title')}
              />
              <Text className="mt-2">{placeData.contactName}</Text>
              <Text size="small" color="steel">
                {placeData.contactTelephone}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Modal.Footer alignment="spread">
        <CTAButton
          colorType="secondary"
          onClick={() =>
            dispatch({
              type: ACTIONS.SET_STEP,
              payload: from,
            })
          }>
          {t('facility-confirmation-modal-button-back')}
        </CTAButton>
        <CTAButton
          type="submit"
          onClick={onConfirmClick}
          disabled={updatePlaceMutation.isLoading}
          styles={{
            common: {
              minWidth: '128px',
            },
          }}>
          {updatePlaceMutation.isLoading ? (
            <SpinnerIcon className="h-5 w-5 animate-spin text-nuvo-white" />
          ) : (
            t('facility-confirmation-modal-button-confirm')
          )}
        </CTAButton>
      </Modal.Footer>
    </>
  );
};

export default ConfirmationWizard;
