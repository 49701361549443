import { Text } from '@nuvocargo/nuvo-styleguide';
import { Spinner } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';
import { Row } from './styles';

const ErrorsAndLoader = ({
  children,
  isInactiveRoute,
  isLoading,
  isOnFinancialHold,
  isRouteError,
  placesAreBroken,
  routeHasNoCompany,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Row>
        <Spinner />
      </Row>
    );
  }

  if (isRouteError) {
    return (
      <Row>
        <Text color="red">{t('shipment-form-route-api-error')}</Text>
      </Row>
    );
  }

  if (isInactiveRoute) {
    return (
      <Row>
        <Text color="red">{t('shipment-form-route-not-active-error')}</Text>
      </Row>
    );
  }

  if (routeHasNoCompany) {
    return (
      <Row>
        <Text color="red">{t('shipment-form-route-has-no-company')}</Text>
      </Row>
    );
  }

  if (placesAreBroken) {
    return (
      <Row>
        <Text color="red">
          {t('shipment-form-places-are-not-associated-to-company')}
        </Text>
      </Row>
    );
  }

  if (isOnFinancialHold) {
    return (
      <Row>
        <Text color="red">{t('company-financial-hold-cannot-ship')}</Text>
      </Row>
    );
  }

  return <>{children}</>;
};

export default ErrorsAndLoader;
