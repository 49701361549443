import React from 'react';
import { useTranslation } from 'react-i18next';
import { CTAButton, Eyebrow, Heading } from '@nuvocargo/nuvo-styleguide';
import Modal from '@nuvocargo/nuvo-styleguide/Modals/Modal';
import { Formik, Form } from 'formik';

import { formatAddressOneLine } from 'core/utils/routeUtils';

import { ACTIONS, STEPS } from '../../../Facilities';

import Option from './components/Option';

const SelectExistingPlaceForm = ({ placeData, places, dispatch }) => {
  const { zipCode: zipCodeObject } = placeData;
  const { t } = useTranslation();

  const optionName = 'selectPlaceOption';

  return (
    <Formik
      initialValues={{
        [optionName]: places[0].id,
      }}
      onSubmit={({ selectPlaceOption }) => {
        const newPlace = places.find(({ id }) => id === selectPlaceOption);
        dispatch({
          type: ACTIONS.SET_CONFIRMATION_STEP,
          payload: {
            modalData: newPlace,
            from: STEPS.SELECT_EXISTING_PLACE_FORM,
          },
        });
      }}>
      <Form>
        <div className="flex">
          <Heading variant="h5" color="green">
            {t('facility-existing-place-modal-title')}
          </Heading>
        </div>

        <div className="mt-9 flex flex-col gap-4">
          <Eyebrow
            color="steel"
            size="small"
            text={t('facility-existing-place-modal-select-place')}
          />
          {places.map(current => (
            <Option
              value={current.id}
              key={current.id}
              name={optionName}
              title={current.name}
              description={formatAddressOneLine({
                addressLine1: current.addressLine1,
                zipCode: zipCodeObject,
              })}
            />
          ))}
        </div>
        <Modal.Footer>
          <CTAButton type="submit">
            {t('facility-existing-place-modal-button-continue')}
          </CTAButton>
        </Modal.Footer>
      </Form>
    </Formik>
  );
};

export default SelectExistingPlaceForm;
